body {
    background: #000000;
    color: #FFFFFF;
}

.bg-main {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 1)), url('../public/images/backgrounds/header-main-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}

/* utility styles*/

.opacity-bg {
    background: linear-gradient(to right, black, transparent, black);
    padding: 20px;
}

.spacer {
    margin-top: 30px;
    margin-bottom: 30px;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.button {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    padding: 8px 20px;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 30px;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
  
.button:hover {
    background: none;
    color: #FFFFFF;
    transition: 1s;
}

.social-icons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.social-icon {
    width: 20px;
    height: 20px;
}

.social-icon:hover {
    filter: invert(75%);
    transition: .3s;
}

/* loader styles */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.loader-white {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #333333;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
}

.loader-black {
    border: 5px solid #333333;
    border-top: 5px solid #f3f3f3;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* pages shared styles */

/* home styles */

.main-header {
    padding: 30px 30px 30px 20px;
}

.main-header-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    gap: 20px;
    color: #FFFFFF;
}

h1.header-title {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 5px;
}

h3.header-text {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 5px;
}

.home-content-container {
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, black 30%, black 80%, rgba(0, 0, 0, 0.5) 100%);
    padding: 50px 0 20px 0;
    text-align: center;
}

.download-btn-icon {
    padding: 10px 0 20px 0;
    cursor: pointer;
}

/* about styles */

.about-page-container {
    padding: 25px;
    text-align: left !important;
}

h1.page-title {
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    line-height: 1.5;
    margin-top: 10px;
    margin-bottom: 10px;
}

h3.page-headline {
    font-size: 27px;
    font-weight: 700;
    text-align: left;
    line-height: 1.5;
    margin-top: 10px;
    margin-bottom: 10px;
}

p.page-text {
    font-size: 16px;
    line-height: 2.3;
    width: 50%;
}

p.about-text {
    font-size: 16px;
    line-height: 2.3;
}

.screenshot-container {
    background: rgba(0, 0, 0, 0.05);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.screenshot {
    height: 500px;
    width: 750px;
}

/* jobs styles */

.info-container {
    margin-bottom: 30px;
}

ul.job-list {
    list-style-type: disc;
    margin: 0;
    padding: 0;
}

li.job-list-item {
    font-size: 16px;
    line-height: 2.3;
    margin-bottom: 8px;
    width: 50%;
}

span.list-item-title {
    font-weight: bold;
}

a.twitter-link {
    font-weight: bold;
    color: #1DA1F2;
    text-decoration: none;
}

a.twitter-link:hover {
    text-decoration: underline;
}

/* article/post/guide styles */

.article-page-container {
    background: rgba(0, 0, 0, 0.9);
}

.article-article {
    padding: 30px;
}

.article-metadata {
    margin-bottom: 20px;
}

h1.article-title {
   font-size: 28px;
   margin-bottom: 15px;
}

h3.article-description {
    font-size: 18px;
    margin-bottom: 15px;
}

h5.article-date {
    font-size: 13px;
    margin-bottom: 15px;
}

.article-body p {
    line-height: 1.8;
    color: #FFFFFF;
}

.article-body img {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 15px;
}

.categories {
    display: flex;
    gap: 10px;
}
  
.category-pill {
    background-color: #FFFFFF;
    color: #000000;
    padding: 8px 12px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    text-transform: capitalize;
}
  
.category-pill:hover {
    background-color: #333333;
    color: #FFFFFF;
}

/* document styles */

.document-date {
    display: block;
    font-size: 13px;
    font-weight: bold;
    padding-top: 20px;
    margin-bottom: 30px;
    text-decoration: underline;
}

.doc-content h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 30px;
    margin-bottom: 15px;
}

.doc-content h3 {
    font-size: 14px;
    line-height: 1.2;
    margin-top: 15px;
    margin-bottom: 15px;
    text-decoration: underline;
}

.doc-content h4 {
    font-size: 13px;
    line-height: 1.2;
    margin-top: 15px;
    margin-bottom: 15px;
}

.doc-content p {
    font-size: 13px;
    line-height: 1.6;
    width: 50%;
}

.doc-content li  {
    font-size: 13px;
    line-height: 1.6;
}

/* not found page styles */

.not-found-page-container {
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 30px;
    padding: 20px;
}

h1.not-found-title {
    font-size: 30px;
}

h3.not-found-description {
    font-size: 18px;
}

p.not-found-text {
    font-size: 15px;
    line-height: 1.8;
}

/* app docs styles */

.docs-page {
    background: #FFFFFF;
    color: #000000;
    padding: 0 10px 20px 10px;
}

/* footer styles */

.main-footer {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), transparent);
    width: 100%;
}

.main-footer-content {
    text-align: center;
    padding: 50px 0;
}

ul.main-footer-links {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 20px;
}

ul.main-footer-links li {
    display: inline;
    padding: 8px;
}

ul.main-footer-links li a {
    font-size: 15px;
    text-decoration: none;
    color: #FFFFFF;
}

ul.main-footer-links li a:hover {
    color: #CCCCCC;
    transition: .3s;
}

.footer-text {
    font-size: 15px;
    color: #FFFFFF;
}

/*------------------------------------------------------------------------*/
/* smaller screens responsive styles */

@media (max-width: 768px) {

    h1.header-title {
        font-size: 30px;
        padding-left: 15px;
    }

    h1.page-title {
        font-size: 20px;
    }

    h3.header-text {
        font-size: 20px;
        padding-left: 15px;
        text-align: center;
    }

    h3.page-headline {
        font-size: 20px;
    }

    p.page-text {
        font-size: 14px;
        width: 100%;
    }

    li.job-list-item {
        font-size: 14px;
        width: 100%;
    }

    .document-date {
        font-size: 12px;
    }

    .doc-content p {
        font-size: 14px;
        width: 100%;
    }

    .doc-content li {
        font-size: 14px;
    }

    ul.main-footer-links li a, .footer-text {
        font-size: 12px;
    }
}