.navigation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.hamburger-icon {
  font-size: 23px;
  color: #FFFFFF;
  cursor: pointer;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dimmed background color */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-popup {
  background: white;
  color: black;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  width: 250px;
}

.close-icon {
  font-size: 23px;
  cursor: pointer;
  text-align: right;
}

ul.navbar-links {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

li.navbar-link {
  margin-bottom: 30px;
}

li.navbar-link a {
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
  transition: color 0.3s;
}

li.navbar-link a:hover {
  color: #CB6CE6;
  cursor: pointer;
}